<template>
    <v-card color="transparent" height="100%" class="d-flex flex-row align-center">
        <v-navigation-drawer hidden permanent style="height: 100%;">
            <v-slide-x-transition group>
            <v-list-item key="1">
                <v-list-item-content>
                    <BT-Select
                        navigation="locations"
                        v-model="locationID"
                        itemValue="id"
                        hide-details
                        itemText="locationName"
                        label="Location"
                        :canSelectNone="false" />
                    </v-list-item-content>
                </v-list-item>
            
                <v-list-item v-if="locationID != null" dense key="2">
                    <!-- <v-btn-toggle v-model="cautionFilter" dense class="mx-auto justify-space-between">
                        <v-btn value="caution" icon><v-icon>mdi-alert</v-icon></v-btn>
                    </v-btn-toggle> -->
                    <v-btn-toggle v-model="productFilter" dense class="mx-auto justify-space-between">                        
                        <v-btn value="Make">Make</v-btn>
                        <v-btn value="Acquire">Acquire</v-btn>
                    </v-btn-toggle>
                </v-list-item>

                <v-toolbar v-if="locationID != null" key="3" dense>
                    <v-text-field
                        dense
                        v-model="searchString"
                        hide-details
                        :append-icon="searchString != null ? 'mdi-close' : 'mdi-magnify'"
                        @click:append="searchString = null">
                    </v-text-field>
                </v-toolbar>

                <BT-Select-List
                    v-if="locationID != null"
                    key="4"
                    navigation="products"
                    :multiple="false"
                    :onFilter="filterProducts"
                    :onPullSuccessAsync="populateProducts"
                    :searchString="searchString"
                    :searchProperties="['productName', 'abbreviation']"
                    height="75vh"
                    v-model="currentProduct">
                    <template slot-scope="{ item }">
                        <v-list-item-avatar>
                            <v-icon v-if="isStockWarning(item)" color="red">mdi-alert</v-icon>
                            <v-icon v-else color="success">mdi-check</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.productName }}</v-list-item-title>
                            <v-list-item-subtitle>{{ item.abbreviation }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </BT-Select-List>
            </v-slide-x-transition>
        </v-navigation-drawer>
             
        <v-card class="flex align-center">
            <v-slide-x-transition>
                <v-container v-if="currentProduct != null">
                <v-row class="justify-space-between">
                <v-col class="d-flex align-center justify-center">
                    <v-card flat class="text-center" max-width="300">
                        <v-img
                            :src="productLogoURL(currentProduct.id)"
                            height="150"
                            contain
                            class="mx-auto">
                             <template v-slot:placeholder>
                                <v-icon size="100" color="primary">mdi-cube-outline</v-icon>
                            </template>
                        </v-img>
                        <v-card-title class="mx-auto px-auto">{{ currentProduct.productName }}</v-card-title>
                        <v-slide-y-transition>
                        <v-card-text v-if="currentTrackedProduct != null">
                            <v-list>
                            <v-list-item-group v-model="selectedItem">
                            <v-list-item value="onRequest">
                                <v-list-item-icon>
                                    <v-icon v-if="showOnRequest" color="red">mdi-minus</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>On Request</v-list-item-title>
                                    <v-list-item-subtitle>QTY: {{ currentTrackedProduct.onRequest | toDisplayNumber }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon @click.stop="showOnRequest = !showOnRequest">
                                        <v-icon color="blue-grey darken-2">{{ showOnRequest ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
                                    </v-btn>                                    
                                </v-list-item-action>                                
                            </v-list-item>
                            <v-list-item value="consigned">
                                <v-list-item-icon>
                                    <v-icon v-if="showConsigned" color="red">mdi-minus</v-icon>
                                </v-list-item-icon>                       
                                <v-list-item-content>
                                    <v-list-item-title>Consigned</v-list-item-title>
                                    <v-list-item-subtitle>QTY: {{ currentTrackedProduct.consigned | toDisplayNumber }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon @click.stop="showConsigned = !showConsigned">
                                        <v-icon color="teal darken-2">{{ showConsigned ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
                                    </v-btn>      
                                </v-list-item-action>                                                              
                            </v-list-item>
                            <!-- <div class="text-right">Needed: {{ needs | toDisplayNumber }}</div> -->
                            <!-- <v-divider />
                            <v-list-item>
                                <v-list-item-content class="ma-0 pa-0">
                                    <v-list-item-title>Needed: </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action class="ma-0 pa-0">{{ needs | toDisplayNumber }}</v-list-item-action>
                            </v-list-item>                          
                            <v-divider /> -->
                            <v-list-item value="onOrder">
                                <v-list-item-icon>
                                    <v-icon v-if="showOnOrder" color="success">mdi-plus</v-icon>
                                </v-list-item-icon>                       
                                <v-list-item-content>
                                    <v-list-item-title>On Order</v-list-item-title>
                                    <v-list-item-subtitle>QTY: {{ currentTrackedProduct.onOrder | toDisplayNumber }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon @click.stop="showOnOrder = !showOnOrder">
                                        <v-icon color="orange darken-2">{{ showOnOrder ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
                                    </v-btn>      
                                </v-list-item-action>
                            </v-list-item>                                                        
                            <v-list-item value="inStock">
                                <v-list-item-icon>
                                    <v-icon v-if="showInStock" color="success">mdi-plus</v-icon>
                                </v-list-item-icon>                       
                                <v-list-item-content>
                                    <v-list-item-title>In Stock</v-list-item-title>
                                    <v-list-item-subtitle>QTY: {{ currentTrackedProduct.inStock | toDisplayNumber }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon @click.stop="showInStock = !showInStock">
                                        <v-icon color="green darken-2">{{ showInStock ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
                                    </v-btn>      
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item v-if="currentTrackedProduct.lowerThreshold != null" value="threshold">
                                <v-list-item-icon>
                                    <v-icon v-if="showThreshold && thresholdNeeds > 0" color="red">mdi-alert</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Threshold</v-list-item-title>
                                    <v-list-item-subtitle>QTY: {{ currentTrackedProduct.lowerThreshold | toDisplayNumber }} to {{ currentTrackedProduct.upperThreshold | toDisplayNumber }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon @click.stop="showThreshold = !showThreshold">
                                        <v-icon color="pink">{{ showThreshold ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
                                    </v-btn>      
                                </v-list-item-action>                                    
                            </v-list-item> 
                            </v-list-item-group>                                                                                   
                            </v-list>
                        </v-card-text>
                        </v-slide-y-transition>
                    </v-card>
                </v-col>
                <v-col v-if="currentTrackedProduct != null && hundredthPercentile == 0">
                </v-col>
                <v-col v-else-if="currentTrackedProduct != null" class="d-flex justify-center">                    
                    <!-- <v-slide-y-transition group> -->
                    <!-- <v-progress-circular 
                        width="1" 
                        size="120"
                        color="black" 
                        class="progress"
                        value="100" /> -->

                    <v-progress-circular                        
                        key="1"
                        size="200"                        
                        width="20"                        
                        class="progress align-self-center"
                        :color="selectedItem=='inStock' ? 'pink' : 'green darken-2'"
                        :value="(inStock / hundredthPercentile)*100">
                        <v-slide-x-transition>
                            <div v-if="selectedItem == 'inStock'" class="text-center">
                                <h4>In Stock</h4>
                                <label>{{ inStock | toDisplayNumber }}</label>
                            </div>
                        </v-slide-x-transition>
                    </v-progress-circular>
                    <v-progress-circular
                        key="2"
                        size="200"                        
                        width="20"                        
                        class="progress align-self-center"
                        :color="selectedItem=='onOrder' ? 'pink' : 'orange darken-2'"
                        :rotate="((inStock / hundredthPercentile)*360)"
                        :value="(onOrder / hundredthPercentile)*100">
                        <v-slide-x-transition>
                            <div v-if="selectedItem == 'onOrder'" class="text-center">
                                <h4>On Order</h4>
                                <label>{{ onOrder | toDisplayNumber }}</label>
                            </div>
                        </v-slide-x-transition>
                    </v-progress-circular>
<!--                     
                    <v-progress-circular 
                        width="1" 
                        size="160"
                        color="black" 
                        class="progress"
                        value="100" /> -->

                    <v-progress-circular                            
                        key="3"
                        size="240"
                        width="20"        
                        class="progress align-self-center"                       
                        :color="selectedItem=='consigned' ? 'pink' : 'teal darken-2'"
                        :value="(consigned / hundredthPercentile)*100">
                        <v-slide-x-transition>
                            <div v-if="selectedItem == 'consigned'" class="text-center">
                                <h4>To Fulfill</h4>
                                <label>{{ consigned | toDisplayNumber }}</label>
                            </div>
                        </v-slide-x-transition>
                    </v-progress-circular>
                    <v-progress-circular                        
                        key="4"
                        size="240"                                                
                        width="20"        
                        class="progress align-self-center"
                        :color="selectedItem=='onRequest' ? 'pink' : 'blue-grey darken-2'"
                        :rotate="((consigned / hundredthPercentile)*360)"
                        :value="(onRequest / hundredthPercentile)*100">
                        <v-slide-x-transition>
                            <div v-if="selectedItem == 'onRequest'" class="text-center">
                                <h4>On Request</h4>
                                <label>{{ onRequest | toDisplayNumber }}</label>
                            </div>
                        </v-slide-x-transition>
                    </v-progress-circular>
<!--                     
                    <v-progress-circular 
                        width="1" 
                        size="200"
                        color="black" 
                        class="progress"
                        value="100" /> -->

                    <v-progress-circular
                        v-if="upperThreshold != null"
                        key="5"
                        size="280"
                        width="20"                        
                        class="progress align-self-center"
                        :color="selectedItem=='threshold' ? 'pink' : 'deep-purple'"
                        :value="(upperThreshold / hundredthPercentile)*100" >
                        <v-slide-x-transition>
                            <div v-if="selectedItem == 'threshold' && thresholdNeeds == 0" class="text-center">
                                <h4>Threshold Level</h4>
                                <label>{{ thresholdPercentage | toDisplayNumber }}%</label>
                            </div>
                            <div v-else-if="selectedItem == 'threshold'" class="text-center red--text">                                
                                <p><v-icon color="red">mdi-alert</v-icon></p>
                                <h4>{{ thresholdNeeds | toDisplayNumber }}</h4>
                                <h3>Needed</h3>
                            </div>
                        </v-slide-x-transition>                    
                    </v-progress-circular>
                    <!-- </v-slide-y-transition> -->                    
                    <!-- <v-list-item class="align-self-bottom">
                        <v-list-item-content>
                            <v-list-item-title>Available</v-list-item-title>
                            <v-list-item-subtitle>{{ available | toDisplayNumber }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>                     -->
                </v-col>                
                </v-row>                
                </v-container>
            </v-slide-x-transition>                
        </v-card>
    </v-card>    
</template>

<script>
export default {
    name: 'Stock-Levels',
    data: function() {
        return {
            currentProduct: null,
            drawer: true,
            loading: false,
            locationID: null,
            productFilter: null, //'Make', //Acquire       
            cautionFilter: null,     

            batches: null,
            tethers: null,
            thresholds: null,
            trackedItems: null,
            searchString: null,
            selectedItem: null,

            showOnRequest: true,
            showConsigned: true,
            showOnOrder: true,
            showInStock: true,
            showThreshold: true
        }
    },   
    computed: {
        onRequest() { return this.currentTrackedProduct == null || !this.showOnRequest ? 0 : this.currentTrackedProduct.onRequest; },
        inStock() { return this.currentTrackedProduct == null || !this.showInStock ? 0 : this.currentTrackedProduct.inStock; },
        consigned() { return this.currentTrackedProduct == null || !this.showConsigned ? 0 : this.currentTrackedProduct.consigned; },
        onOrder() { return this.currentTrackedProduct == null || !this.showOnOrder ? 0 : this.currentTrackedProduct.onOrder; },
        lowerThreshold() { return this.currentTrackedProduct == null || !this.showThreshold ? null : this.currentTrackedProduct.lowerThreshold; },
        upperThreshold() { return this.currentTrackedProduct == null || !this.showThreshold ? null : this.currentTrackedProduct.upperThreshold; },        
        //tbc + tofulfill
        needs() { return (this.onRequest == null ? 0 : this.onRequest) + (this.consigned == null ? 0 : this.consigned) },
        //instock + onorder
        available() { return (this.inStock == null ? 0 : this.inStock) + (this.onOrder == null ? 0 : this.onOrder) },
        //upper threshold - (available - needs)
        upperThresholdNeeds() { return (this.upperThreshold == null ? null : this.upperThreshold) - (this.available - this.needs) },
        //after lower threshold is triggered
        thresholdNeeds() {
            if (this.upperThreshold == null || this.lowerThreshold == null) {
                return 0;
            }
            var dif = (this.available - this.needs);
            if (dif < this.lowerThreshold) {
                return this.upperThreshold - dif;
            }

            return 0;
        },        
        currentTrackedProduct() {
            // return {
            //     tbc: 10,
            //     toFulfill: 10,
            //     inStock: 15,
            //     onOrder: 5,
            //     lowerThreshold: 5,
            //     upperThreshold: 30
            // };
            if (this.currentProduct == null) {
                return null;
            }
            else {
                return this.currentProduct.locations.find(y => y.locationID == this.locationID);                
            }
        },
        hundredthPercentile() {
            return Math.max(
                this.upperThreshold != null ? this.upperThreshold : 0,
                this.onRequest + this.consigned,
                this.inStock + this.onOrder
            )
        },
        neededPercentile() {
            if (this.currentTrackedProduct == null) {
                return 0;
            }
            var t = this.currentTrackedProduct;
            return (t.onRequest + t.consigned) / this.hundredthPercentile;
        },
        inStockPercentile() {
            if (this.currentTrackedProduct == null) {
                return 0;
            }
            var t = this.currentTrackedProduct;
            return t.inStock / this.hundredthPercentile;
        },
        thresholdPercentage() {
            if (this.upperThreshold == null) {
                return 100;
            }

            return Math.round((((this.available - this.needs)-this.lowerThreshold) / (this.upperThreshold-this.lowerThreshold)) * 100);
        }
    },
    created() {
        var locationID = this.$BlitzIt.auth.session?.data?.defaultLocationID;
        this.locationID = locationID != null ? locationID : null;
    },
    methods: {
        filterProducts(list) {
            if (this.locationID == null) {
                return [];
            }
            var sList = list;
            if (this.productFilter == 'Make') {
                return sList.filter(x => x.isManufactured).orderBy(x => x.sortNumber);
            }
            else if (this.productFilter == 'Acquire') {
                return sList.filter(x => x.isAcquired).orderBy(x => x.sortNumber);
            }
            else {
                return sList.orderBy(x => x.sortNumber);
            }
        },
        async populateProducts(list, refresh) {
            if (this.thresholds == null) {
                this.thresholds = await this.$BlitzIt.store.getAll('stock-thresholds', { includeDetails: false }, refresh, null);
            }
            if (this.trackedItems == null) {
                this.trackedItems = await this.$BlitzIt.store.getAll('stock-tracked-items', { includeDetails: false }, refresh, null);
            }
            if (this.tethers == null) {
                this.tethers = await this.$BlitzIt.store.getAll('product-tethers', { includeDetails: false }, refresh, null);
            }   
            if (this.batches == null) {
                this.batches = await this.$BlitzIt.store.getAll('batches', { filterBy: 'Pending' }, refresh, null);
            }
            //return list;
            return list.map(x => Object.assign({}, x, {
                locations: this.getLocationItemList(x.id)
            }));
        },
        getLocationItemList(productID) {                        
            return this.trackedItems
                .filter(x => x.productID == productID)
                .map(t => this.copyDeep(t))
                .map(t => {
                    //tether on order                                        
                        this.tethers.forEach(tether => {
                            if (tether.isAcquired && tether.triggeringProductID == t.productID) {
                                console.log('acquire');
                                var tItems = this.trackedItems.filter(y => y.locationID == t.locationID && y.productID == tether.tetheredProductID);
                                console.log(tItems);
                                t.onOrder += tItems.sum(y => y.onOrder);                                
                            }
                        })
                        return t;                    
                })
                .map(t => {      
                    console.log(t);              
                    //tether pending batches                    
                        this.tethers.forEach(tether => {
                            if (tether.tetheredProductID == t.productID && tether.isManufactured) {                                
                                t.consigned += this.batches.filter(batch => batch.locationID == t.locationID && batch.productID == tether.triggeringProductID).sum(y => y.quantity);
                            }
                        })
                        return t;                    
                })
                .map(y => Object.assign({}, y, this.getThresholdInfo(y.productID, y.locationID)));
        },
        getThresholdInfo(productID, locationID) {
            var info = {
                upperThreshold: null,
                lowerThreshold: null
            };

            if (locationID != null && productID != null) {
                var threshold = this.thresholds.find(y => y.productID == productID && y.locationID == locationID);
                if (threshold != null) {
                    info.upperThreshold = threshold.upperThreshold;
                    info.lowerThreshold = threshold.lowerThreshold;
                }
            }

            return info;
        },
        isStockWarning(product) {
            if (this.locationID == null) {
                return false;
            }            

            var info = product.locations.find(y => y.locationID == this.locationID);

            if (info == null) {
                return false;
            }

            var lowerThreshold = (info != null && info.lowerThreshold != null) ? info.lowerThreshold : 0;
            
            var amount = info.inStock - info.consigned + info.onOrder;            
            
            return amount <= lowerThreshold;
        }
    }
}
</script>

<style scoped>

.progress {
    position: absolute;
    top: auto;
    bottom: auto;    
}

</style>